<template>
  <v-container class="pa-0">
    <v-dialog :width="this.size != null ? size : '400'" v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-list-item class="braList" v-on="on">
          <v-list-item-icon>
            <v-icon small style="margin-top: -3px !important"
              >$editprofile</v-icon
            ></v-list-item-icon
          >
          <v-list-item-title>
            <span>{{ $t("edit", { name: "" }) }}</span></v-list-item-title
          >
        </v-list-item>
      </template>

      <v-card>
        <v-card-title>
          {{ $t("routes.categoryEdit") }}
        </v-card-title>
        <v-card-text>
          <categorys-form
            :title="$t('new', { name: $tc('category') })"
            :category="category"
            @validated="updateCategory"
            @back="dialog = false"
          ></categorys-form
        ></v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CategoryEdit",
  components: { CategorysForm: () => import("@/components/category/Form") },
  props: ["id"],
  mounted() {
    this.fetchCategory();
  },
  data() {
    return {
      categorySaved: false,
      validationErrors: [],
      category: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("category", ["editCategory", "getCategory"]),
    fetchCategory() {
      this.getCategory(this.id).then((response) => {
        this.category = response;
      });
    },
    updateCategory() {
      //console.log("update");
      this.editCategory({
        category_id: this.id,
        category: this.category,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("category_edit_success"), "", "");
          this.$emit("updated");
          this.dialog = false;
        }
      });
    },
  },
};
</script>